// Vendor
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { css, cx as emotionClassNames } from '@emotion/css';
import classNames from 'classnames';

// Local
import ProgressiveImage from 'shared/components/ProgressiveImage';
import { ExternalLink } from 'shared/components/WaymarkLinks';
import { cmsURLs } from 'app/constants/urls';
import * as selectors from 'app/state/selectors/index.js';
import { getImgixUrl } from 'shared/utils/urls.js';

// Styles
import { blackColor } from 'styles/themes/waymark/colors.js';
import breakpoints from 'styles/constants/breakpoints.js';

import * as styles from './WaymarkLogo.css.ts';

const logoImageURL = getImgixUrl('app/components/waymark_header/waymark-header-logo.png');

/**
 * Creates a Waymark logo.
 * The logo padding is equal to the logo height.
 */
export const WaymarkLogoImage = ({ className = null, ...props }) => (
  <ProgressiveImage
    src={logoImageURL}
    alt="Waymark"
    className={classNames(styles.waymarkLogoImage, className)}
    shouldFillWidth
    isTransparent
    {...props}
  />
);

/**
 * Creates a Waymark logo.
 * The logo padding is equal to the logo height.
 *
 * @param {React.ComponentPropsWithoutRef<"svg"> & { color?: string; className?: string; }} props
 */
export const WaymarkLogoSVG = ({ color = blackColor, className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 830.4 161"
    className={emotionClassNames(
      css`
        height: 24px;
        width: auto;

        @media ${breakpoints.small.queryDown} {
          height: 20px;
        }
      `,
      className,
    )}
    {...props}
  >
    <title>Waymark</title>
    <path
      fill={color}
      d="M260.8,37.6v79.2h-31.2V105c-2.9,6.5-12.9,13.9-24.2,13.9c-21.7,0-36.8-16.8-36.8-41.6s15.1-41.6,36.8-41.6
        c11.3,0,21.4,7.2,24.2,13.7V37.6H260.8z M229.5,77.3c0-8.6-6.1-15.6-15-15.6c-8.6,0-14.7,7-14.7,15.6c0,8.4,6.1,15.6,14.7,15.6
        C223.5,92.9,229.5,85.7,229.5,77.3z M289.5,146.4c-7-5.6-11.1-14-11.2-23h31.4c0.3,6.5,4.8,10.7,12,10.7c8.1,0,12.1-3.7,12.1-13.9
        v-17.4c-4.1,8.3-12.9,13.4-24.9,13.4c-9.9,0-17.9-3.7-22-8.3c-5.9-6.2-7.3-14-7.3-25.8V37.6H311v40.2c0,9.4,2.9,15,10.9,15
        c7.8,0,12-5.6,12-16.4V37.6h31.2v76.2c0,13.2-4.9,26.5-12,32.1c-7.6,6-16.1,8.4-31.7,8.4C306.5,154.3,296.8,152.3,289.5,146.4
        M482.4,116.8V75.5c0-9.1-2.9-13.9-9.4-13.9c-6.2,0-10.5,4-10.5,15.6v39.5h-29.7V75.5c0-9.1-2.7-13.9-9.4-13.9
        c-6.2,0-10.5,4.1-10.5,16.6v38.6h-29.7V37.6h29.7v11.6c3.5-7.5,11.8-13.5,23.6-13.5c13.4,0,20.1,5.9,23.3,14.3
        c4.5-8.5,13.2-14.3,25.2-14.3c22.5,0,27.3,15,27.3,33.6v47.5H482.4z M618,37.6v79.2h-31.3V105c-2.9,6.5-12.9,13.9-24.2,13.9
        c-21.7,0-36.8-16.8-36.8-41.6s15.2-41.6,36.8-41.6c11.3,0,21.4,7.2,24.2,13.7V37.6H618z M586.7,77.3c0-8.6-6.1-15.6-15-15.6
        c-8.6,0-14.7,7-14.7,15.6c0,8.4,6.1,15.6,14.7,15.6C580.7,92.9,586.7,85.7,586.7,77.3z M699.6,65.6c-3.5-1-7.1-1.5-10.7-1.4
        c-12,0-21.2,5.6-21.2,24.7v27.9h-31.2V37.6h31.2v12.6C671.2,43,680.2,36,691.5,36c2.8-0.1,5.5,0.3,8.1,1.1V65.6z M699.6,65.6
        c-3.5-1-7.1-1.5-10.7-1.4c-12,0-21.2,5.6-21.2,24.7v27.9h-31.2V37.6h31.2v12.6C671.2,43,680.2,36,691.5,36c2.8-0.1,5.5,0.3,8.1,1.1
        V65.6z M771.5,77.3l28.7,39.5h-36.5l-19.9-31.7v31.7h-31.2V5.2h31.2v64.3l20.1-31.9h36.3L771.5,77.3z M771.5,77.3l28.7,39.5h-36.5
        l-19.9-31.7v31.7h-31.2V5.2h31.2v64.3l20.1-31.9h36.3L771.5,77.3z M92.7,56.1l-18.7,60.8H45.2L5,5.2h35.1l19.8,66.7L79.5,5.2h26.5
        l19.5,66.7l19.9-66.7h35.1l-40.2,111.6h-29L92.7,56.1z M92.7,56.1l-18.7,60.8H45.2L5,5.2h35.1l19.8,66.7L79.5,5.2h26.5l19.5,66.7
        l19.9-66.7h35.1l-40.2,111.6h-29L92.7,56.1z"
    />
  </svg>
);

const WhitelabeledLogo = (color, className, ...props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="420 0 1080 1080"
    height="100%"
    className={emotionClassNames(
      css`
        height: 46px;
        width: auto;
      `,
      className,
    )}
    {...props}
  >
    <title>Logo</title>
    <g>
      <path
        fill={color}
        d="M1002.95,508.09c-9.27,0-16.64,3.65-22.08,10.95c-5.45,7.3-8.17,16.99-8.17,29.04c0,11.82,2.72,21.56,8.17,29.21
		c5.44,7.65,12.81,11.48,22.08,11.48c9.74,0,17.21-3.83,22.43-11.48c5.22-7.65,7.82-17.39,7.82-29.21c0-11.13-2.61-20.57-7.82-28.34
		C1020.16,511.98,1012.68,508.09,1002.95,508.09z"
      />
      <path
        fill={color}
        d="M802.3,504.96c-10.2,0-17.73,4.58-22.6,13.74c-4.87,9.16-7.3,21.27-7.3,36.34c0,15.07,2.43,27.18,7.3,36.34
		c4.87,9.16,12.4,13.74,22.6,13.74c10.2,0,17.85-4.57,22.95-13.74c5.1-9.16,7.65-21.27,7.65-36.34c0-15.07-2.55-27.18-7.65-36.34
		C820.15,509.54,812.5,504.96,802.3,504.96z"
      />
      <path
        fill={color}
        d="M420,0v1080h1080V0H420z M689.28,643.37h-67.46V394.73h67.46V643.37z M892.89,592.42c-4.52,11.48-10.95,21.39-19.3,29.73
		s-18.49,14.9-30.43,19.65c-11.94,4.75-25.33,7.13-40.16,7.13c-14.84,0-28.23-2.38-40.16-7.13c-11.94-4.75-22.14-11.3-30.6-19.65
		c-8.46-8.35-15.01-18.26-19.65-29.73c-4.64-11.48-6.96-23.93-6.96-37.38c0-13.44,2.31-25.91,6.96-37.38
		c4.63-11.48,11.18-21.44,19.65-29.91c8.46-8.46,18.66-15.07,30.6-19.82c11.94-4.75,25.33-7.13,40.16-7.13
		c14.83,0,28.22,2.38,40.16,7.13c11.94,4.75,22.08,11.36,30.43,19.82c8.35,8.46,14.78,18.43,19.3,29.91s6.78,23.94,6.78,37.38
		C899.67,568.49,897.41,580.95,892.89,592.42z M1095.1,622.15c0,14.38-2.38,26.89-7.13,37.56c-4.75,10.66-11.07,19.35-18.95,26.08
		c-8.12,6.96-17.85,11.94-29.21,14.95c-11.36,3.01-23.65,4.52-36.86,4.52c-25.04,0-45.61-4.87-61.72-14.61
		c-16.12-9.74-25.79-24.46-29.04-44.16h63.29c1.16,3.71,3.88,7.13,8.17,10.26c4.29,3.13,10.37,4.7,18.26,4.7
		c10.66,0,18.08-2.84,22.25-8.52c4.17-5.68,6.26-13.15,6.26-22.43v-16h-1.39c-5.34,6.03-11.54,10.9-18.6,14.6
		c-7.07,3.71-15.82,5.56-26.25,5.56c-10.2,0-19.82-1.74-28.86-5.22c-9.04-3.48-17.04-8.75-23.99-15.82
		c-6.96-7.07-12.46-15.82-16.52-26.25c-4.06-10.43-6.08-22.6-6.08-36.51c0-12.75,1.74-24.63,5.22-35.64
		c3.48-11.01,8.35-20.57,14.61-28.69c6.26-8.11,13.85-14.49,22.78-19.13c8.92-4.63,18.83-6.95,29.73-6.95
		c12.52,0,22.78,2.26,30.78,6.78s14.54,10.61,19.65,18.26h1.04v-19.47h62.59V622.15z M1298.7,592.42
		c-4.52,11.48-10.95,21.39-19.3,29.73c-8.35,8.35-18.49,14.9-30.43,19.65c-11.94,4.75-25.33,7.13-40.16,7.13
		c-14.84,0-28.23-2.38-40.17-7.13c-11.94-4.75-22.14-11.3-30.6-19.65c-8.47-8.35-15.01-18.26-19.65-29.73
		c-4.64-11.48-6.96-23.93-6.96-37.38c0-13.44,2.31-25.91,6.96-37.38c4.63-11.48,11.18-21.44,19.65-29.91
		c8.46-8.46,18.66-15.07,30.6-19.82c11.94-4.75,25.33-7.13,40.17-7.13c14.83,0,28.22,2.38,40.16,7.13
		c11.94,4.75,22.08,11.36,30.43,19.82c8.35,8.46,14.78,18.43,19.3,29.91c4.52,11.48,6.78,23.94,6.78,37.38
		C1305.48,568.49,1303.22,580.95,1298.7,592.42z"
      />
      <path
        fill={color}
        d="M1208.11,504.96c-10.2,0-17.73,4.58-22.6,13.74c-4.87,9.16-7.3,21.27-7.3,36.34c0,15.07,2.43,27.18,7.3,36.34
		c4.87,9.16,12.4,13.74,22.6,13.74c10.2,0,17.85-4.57,22.95-13.74c5.1-9.16,7.65-21.27,7.65-36.34c0-15.07-2.55-27.18-7.65-36.34
		C1225.96,509.54,1218.31,504.96,1208.11,504.96z"
      />
    </g>
  </svg>
);

// A Waymark logo wrapped in a navigation link to the homepage
export const WaymarkLogoHomeButton = ({
  brandedImageClassName = null,
  defaultLogoClassName = null,
  ...props
}) => {
  const { brandedHeaderLogoURL, isSiteWhitelabeled } = useSelector((state) => ({
    brandedHeaderLogoURL: selectors.getBrandingHeaderLogo(state),
    isSiteWhitelabeled: selectors.getBrandingProfileIsSiteWhitelabeled(state),
  }));

  const logo = useMemo(() => {
    if (brandedHeaderLogoURL) {
      return (
        <div
          className={css`
            display: flex;
            align-items: center;
            height: 100%;
          `}
        >
          {!isSiteWhitelabeled && <WaymarkLogoImage className={defaultLogoClassName} />}
          <img
            src={brandedHeaderLogoURL}
            alt=""
            className={emotionClassNames(
              css`
                padding-left: 12px;
                max-height: 60px;
                max-width: 260px;

                @media ${breakpoints.small.queryDown} {
                  max-height: 32px;
                }
              `,
              brandedImageClassName,
            )}
          />
        </div>
      );
    }

    return (
      <div
        className={css`
          display: flex;
          align-items: center;
          height: 100%;
        `}
      >
        {isSiteWhitelabeled ? (
          <WhitelabeledLogo className={defaultLogoClassName} />
        ) : (
          <WaymarkLogoImage className={defaultLogoClassName} />
        )}
      </div>
    );
  }, [brandedHeaderLogoURL, isSiteWhitelabeled, brandedImageClassName, defaultLogoClassName]);

  return (
    <ExternalLink linkTo={cmsURLs.home} {...props}>
      {logo}
    </ExternalLink>
  );
};
